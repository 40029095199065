<div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left header-section">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-20 pr-20 commonStyle float-left header-panel">
        <div class="commonStyle float-left headerLeft-panel" routerLink ="">
            <img src='assets/images/logo.png' class="companyLogo float-left" />
            <h1 class="companyName float-left" >Techbrains Innovative Solutions</h1>
            <!-- Techbrains Innovative solutions -->
        </div>
        <div class=" commonStyle float-right headerRight-panel">
            <ul class="menuList headings" >
                <li *ngFor="let menuItem of menuList;let i=index" [ngClass]="{'activeMenu': commonService.selectedMenuItem==menuItem.name}" (click)="gotoContent(menuItem.name,'web')">{{menuItem.name}}</li>
            </ul>
            <div class="menuList mobilemenu pr-10 f-24" (click)="openMenu()">
                <span><i class="fas fa-bars"></i></span>
            </div>
        </div>
    </div>
</div>


<!-- <div class="overlay" *ngIf="openmenu" (click)="openMenu()"></div> -->
<div class="mobphne" *ngIf="openmenu">
        <ul class="m-0 p-0 commonStyle float-left menuList w-100">
         <li *ngFor="let menuItem of menuList;let i=index" class="f-20 w-100" [ngClass]="{'activeMenu': commonService.selectedMenuItem==menuItem.name}" (click)="gotoContent(menuItem.name , 'mobile')">{{menuItem.name}}</li>
        </ul>
</div>