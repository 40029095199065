<div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left appLayout-section">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left appLayout-panel">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left headerLayout-section">
            <app-header></app-header>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left contentLayout-section">
            <router-outlet></router-outlet>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left footerLayout-section">
            <app-footer></app-footer>
        </div>
        <button class="upArrowBtn" *ngIf="showTopArrow" (click)="gotop()"><i class="fas fa-angle-double-up f-22"></i></button>
    </div>
</div>
